<template>
  <v-container fluid>
    <v-row>
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <v-col cols="12">
          <v-radio-group
            v-model="vm.witnesses"
            label="Are there witnesses to the incident/allegation?"
            v-validate="'required'"
            data-vv-as="Are there witnesses to the incident/allegation"
            name="witnesses"
            :error-messages="errors.collect('witnesses')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
        A witness can be any person who knows something about the incident. 
        <template   v-if="vm.witnesses">
        <v-btn @click="addSubject">Add Witness</v-btn>
        <template>
          <v-dialog v-model="dialog" width="600">
            <witnesses-form
              :subject="currentSubject"
              :currentIncident="currentIncident"
              v-on:subject-added="subjectAdded"
              v-on:subject-closed="subjectClosed"
            ></witnesses-form>
          </v-dialog>
        </template>
        <v-data-table
        
          id="list-table"
          ref="datatable"
          :headers="headers"
          :items="vm.subjects"
          item-key="index"
          class="mr-2"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.firstName}}</td>
              <td>{{ props.item.lastName}}</td>

              <td>
                <v-icon
                  slot="activator"
                  small
                  class="mr-2"
                  @click="editSubject(props.item)"
                >mdi-pencil</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
        </template>
        <br />
        <v-btn color="primary" type="submit">Continue</v-btn>
      </v-form>
    </v-row>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import witnesses from "./witnesses-form";
//import * as incidentService from "../../../services/incidentsService";
Vue.use(VeeValidate);

export default {
  name: "witnesses",
  components: {
    "witnesses-form": witnesses
  },
  data: () => ({
    vm: {},
    editedIndex: -1,
    dialog: false,
    subjectsOther: [],
    currentSubject: {},
    headers: [
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "lastName" },
      { text: "Type", value: "subjectType" }
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentIncident() {
      return this.$store.getters.currentIncident;
    }
  },
  methods: {
    init() {
      this.vm =
        this.currentIncident == undefined ||
        this.currentIncident.witnesses == undefined
          ? {}
          : this.currentIncident.witnesses;
    },
    addSubject() {
      this.currentSubject = null;
      this.dialog = true;
    },
    editSubject(subject) {
      this.editedIndex = this.vm.subjects.indexOf(subject);
      this.currentSubject = subject;
      this.dialog = true;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentIncident.witnesses = this.vm;
          this.$store.dispatch("setCurrentIncident", this.currentIncident);
          this.next();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    subjectAdded(data) {
      if (this.editedIndex > -1) {
        Object.assign(this.vm.subjects[this.editedIndex], data);
      } else {
        if (this.vm.subjects == undefined || this.vm.subjects == null) {
          this.vm.subjects = [];
        }
        this.vm.subjects.push(data);
      }
      this.currentIncident.witnesses = this.vm;
      this.$store.dispatch("setCurrentIncident", this.currentIncident);
      this.subjectClosed();
    },
    subjectClosed() {
      this.dialog = false;
      setTimeout(() => {
        this.currentSubject = {};
        this.editedIndex = -1;
        this.$forceUpdate();
      }, 300);
    },
    next() {
      this.$emit("next-step");
    }
  },
  editItem(item) {
    this.$store.commit("setCurrentIncidentSubject", item);
    this.$router.push("/witnesses-add");
  },
  addSubject() {
    this.$store.commit("setCurrentIncident", null);
    this.$router.push("/witnesses-add");
  },
  deleteItem(item) {
    const index = this.currentCase.witnesses.indexOf(item);
    confirm("Are you sure you want to delete this item?") &&
      this.currentCase.witnesses.splice(index, 1);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

