<template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>Subject - Other</v-card-title>

       <v-card-text>
            <v-col cols="12">
              <v-select
                v-model="vm.title"
                :items="titles"
                label="Title"
                v-validate="'required'"
                data-vv-as="Title"
                name="title"
                :error-messages="errors.collect('title')"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="vm.firstName"
                label="First name"
                v-validate="'required'"
                data-vv-as="First name"
                name="firstName"
                :error-messages="errors.collect('firstName')"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="vm.lastName"
                label="Last name"
                v-validate="'required'"
                data-vv-as="Last name"
                name="lastName"
                :error-messages="errors.collect('lastName')"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="vm.relationshipToPerson"
                label="Relationship to person"
                v-validate="'required'"
                data-vv-as="Relationship to person"
                name="relationshipToPerson"
                :error-messages="errors.collect('relationshipToPerson')"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-radio-group
                v-model="vm.hasWitnessBeenContacted"
                label="Has this person been contacted about the incident for further information?"
                v-validate="'required'"
                data-vv-as="Has this person been contacted about the incident for further information"
                name="hasWitnessBeenContacted"
                :error-messages="errors.collect('hasWitnessBeenContacted')"
              >
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="vm.gender"
                :items="gender"
                label="Gender"
                v-validate="'required'"
                data-vv-as="gender"
                name="gender"
                :error-messages="errors.collect('gender')"
              ></v-select>
            </v-col>

            <v-row>
              <v-col cols="6">
                <v-menu
                  v-model="dobMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="vm.dateOfBirth" label="Date of Birth" readonly v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="vm.dateOfBirth" @input="dobMenu = false"></v-date-picker>
                </v-menu>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12">
                <v-text-field
                  v-model="vm.phoneNumber"
                  label="Phone number"
                  v-validate="'required'"
                  data-vv-as="Phone number"
                  name="phoneNumber"
                  :error-messages="errors.collect('phoneNumber')"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="vm.email"
                  label="E-mail address"
                  v-validate="'required|email'"
                  data-vv-as="Email address"
                  name="email"
                  :error-messages="errors.collect('email')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" type="submit">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);
export default {
  name: "notes-form",

  data: () => ({
    vm: {},
    dobMenu: null,
  gender: ["Male", "Female", "Indeterminate", "Intersex", "Unspecified"],
    form: {},
    titles: ["Mr", "Mrs", "Ms", "Miss", "Dr"],
    genders: ["Male", "Female", "Indeterminate", "Intersex", "Unspecified"]
  }),
  watch: {
    subject(value) {
      if (value !== null && value !== undefined) {
        this.init();
      }
    }
  },
  mounted() {
    this.init();
  },
  props: {
    subject: Object,
    currentIncident: Object
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      if (this.subject === undefined || this.subject === null) {
        this.vm = {};
      } else {
        this.vm = this.subject;
      }

      if (
        this.currentIncident.witnesses.subjects === undefined ||
        this.currentIncident.witnesses.subjects === null
      ) {
        this.currentIncident.witnesses.subjects = [];
      }
    },

    close() {
      this.$emit("subject-closed");
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("subject-added", this.vm);
        }
      });
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>

